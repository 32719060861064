<template>
    <div class="Datacollection">
        <div class="Dataco_div">
            <div class="colle_Content">
                <div class="colle_input clearfix">
                    <strong class="fl">
                        主营的一个产品（或服务）是：
                    </strong>
                    <span class="fl">
                        <span class="span_span_inp">{{forms.p1.value}}</span>
                    </span>
                    <button class="fr role_butt" @click="dialogVisibleForm = true">角色分配</button>
                </div>
                <div class="colle_box clearfix">
                    <div class="colle_div fl">
                        <div class="back_one">
                            <div>
                                <span class="colle_div_span">它的用户主要是</span>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <li v-for="(item,index) in forms.p2.values" :key="index">
                                <span>
                                    {{index+1}}、{{item.value}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="colle_div fl">
                        <div class="back_two">
                            <div>
                                <span class="colle_div_span">它的买点是</span>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <li v-for="(item1,index1) in forms.p3.values" :key="index1">
                                <span>
                                    {{index1+1}}、{{item1.value}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="colle_div fl">
                        <div class="back_three">
                            <div>
                                <span class="colle_div_span">价格的定位是</span>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <li v-for="(item2,index2) in forms.p4.values" :key="index2">
                                <span>
                                    {{index2+1}}、{{item2.value}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="colle_div fl">
                        <div class="back_four">
                            <div>
                                <span class="colle_div_span">它很吸引我的地方是</span>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <li v-for="(item3,index3) in forms.p5.values" :key="index3">
                                <span>
                                    {{index3+1}}、{{item3.value}}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="ZLSJ_popup">
            <el-dialog
                    title="角色分配"
                    :visible.sync="dialogVisibleForm"
                    width="500px"
                    :before-close="handleClose"
                    :show-close="false">
                    <div class="roleF_Popup">
                        <el-form :model="Form" ref="Form" label-width="80px" size="small"  @submit.native.prevent>
                            <el-form-item :label="forms.role.value.role1.title">
                                <el-select v-model="Form.role1" :disabled="true">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role2.title">
                                <el-select v-model="Form.role2" :disabled="true">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role3.title">
                                <el-select v-model="Form.role3" :disabled="true"> 
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role4.title">
                                <el-select v-model="Form.role4" :disabled="true">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role5.title">
                                <el-select v-model="Form.role5" :disabled="true">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button type="primary" size="mini" @click="rolexit()">确定</el-button>
                        </span>
                </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogVisibleForm:false,
            Form:{},
            forms:{
                p1:{},
                p2:{
                    values:[]
                },
                p3:{
                    values:[]
                },
                p4:{
                    values:[]
                },
                p5:{
                    values:[]
                },
                role:{
                    value:{
                        role1:{},
                        role2:{},
                        role3:{},
                        role4:{},
                        role5:{}
                    }
                },
                user:{}
            }
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects2({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    this.Form.role1 = this.forms.role.value.role1.name
                    this.Form.role2 = this.forms.role.value.role2.name
                    this.Form.role3 = this.forms.role.value.role3.name
                    this.Form.role4 = this.forms.role.value.role4.name
                    this.Form.role5 = this.forms.role.value.role5.name
                    var str = this.form
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        },
        handleClose(done){

        },
        rolexit(){
            this.dialogVisibleForm = false
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveZLSJ.css';
</style>
<style>
.ZLSJ_popup .el-dialog__body{
    padding: 30px 50px 10px;
}
.ZLSJ_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.ZLSJ_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.ZLSJ_popup .el-form-item__content{
    margin-left: 0!important;
}
.ZLSJ_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: left;
}
.ZLSJ_popup .roleF_Popup .el-form{
    text-align: center;
}
.ZLSJ_popup .roleF_Popup .el-form-item__label{
    text-align: right;
    display: inline-block;
    float:none;
}
.ZLSJ_popup .roleF_Popup .el-form-item__content{
    display: inline-block;
}
.ZLSJ_popup .roleF_Popup .el-form-item--small.el-form-item{
    margin-bottom: 20px;
}
.ZLSJ_popup .el-form-item--small.el-form-item{
    margin-bottom: 12px;
}
.ZLSJ_popup .el-dialog__footer{
    text-align: center;
}
.ZLSJ_popup .el-button--mini{
    width: 80px;
}
</style>